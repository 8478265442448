import React, { useEffect, useState } from 'react';
import './FadingText.css';
import { TikTokOutlined, YoutubeOutlined } from '@ant-design/icons';


const comments = [
    {
        username: 'dancer_queen99',
        comment: 'This is hilarious 😂',
        platform: 'TikTok',
        commentdata: 'hilarious-laugh',
    },
    {
        username: 'musicloverYT',
        comment: 'This beat is 🔥🔥🔥',
        platform: 'YouTube',
        commentdata: 'hot-beat',
    },
    {
        username: 'funnyguy33',
        comment: 'I’ve watched this 10 times now 😭',
        platform: 'TikTok',
        commentdata: 'repeat-watch',
    },
    {
        username: 'gamerzoneYT',
        comment: 'Best gameplay I’ve seen today!',
        platform: 'YouTube',
        commentdata: 'gameplay-highlight',
    },
    {
        username: 'makeupmagic',
        comment: 'Slayed it 💅',
        platform: 'TikTok',
        commentdata: 'makeup-reaction',
    },
    {
        username: 'vlogbroYT',
        comment: 'Nice camera work!',
        platform: 'YouTube',
        commentdata: 'production-quality',
    },
    {
        username: 'catmom_101',
        comment: 'My cat does the same thing 🐱',
        platform: 'TikTok',
        commentdata: 'relatable-cats',
    },
    {
        username: 'learnwithYT',
        comment: 'Thanks, this actually helped!',
        platform: 'YouTube',
        commentdata: 'educational-feedback',
    },
    {
        username: 'comedycentral',
        comment: 'Bro this is gold 😂😂😂',
        platform: 'TikTok',
        commentdata: 'funny-moment',
    },
    {
        username: 'techguruYT',
        comment: 'Well explained as always 🙌',
        platform: 'YouTube',
        commentdata: 'tech-tutorial',
    },
];



const AnimatedComments = () => {
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false); // start fade-out

            setTimeout(() => {
                setIndex((prev) => (prev + 1) % comments.length);
                setFade(true); // start fade-in
            }, 500); // match fade-out duration
        }, 3000); // change text every 3s

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="self-center w-full h-full flex flex-row items-center justify-center">
            <div
                className={`flex flex-row  fade-text ${fade ? 'fade-in' : 'fade-out'}`}
            >
                {comments[index]?.platform === 'TikTok' ? (
                    <TikTokOutlined className="text-[#4e4c4c] text-4xl mr-5" />
                ) : (
                    <YoutubeOutlined className="text-[#4e4c4c] text-4xl mr-5" />
                )}
                <div>
                    <h1 className="font-[Montserrat-Regular] capitalize">{comments[index]?.username}</h1>
                    <p className='font-[Montserrat-Bold] capitalize'>{comments[index]?.comment}</p>
                </div>
            </div>
        </div>

    );
};

export default AnimatedComments;
