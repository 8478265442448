import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t, i18n } = useTranslation();


  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream));
  };

  const isNotRootUrl = () => {
    return window.location.pathname !== '/';
  };

  React.useEffect(() => {
    if (isWebView() && isNotRootUrl()) {
      document.querySelector('#footer').style.display = 'none';
    }
  }, []);

  return (
    <div className="py-10  bg-gradient-to-r from-[#F2419C] to-[#F99F20]" id='footer'>
      {/* <div className="social-set">
        <a href='https://twitter.com/beemiapp' target='_blank' className="social-icon twitter-icon"></a>
        <a href='https://instagram.com/beemiapp' target='_blank' className="social-icon instagram-icon"></a>
        <a href='https://youtube.com/@beemiapp' target='_blank' className="social-icon youtube-icon"></a>
        <a href='https://tiktok.com/@beemiapp' target='_blank' className="social-icon tiktok-icon"></a>
        <a href='https://linkedin.com/company/beemiapp' target='_blank' className="social-icon linkedin-icon"></a>
      </div> */}
      <div className="text-center text-white font-[Montserrat-Regular]">
        <div className="text-lg font-[Montserrat-Bold] mb-5">
         +251985536686
        </div>
        <div className="text-lg font-[Montserrat-Bold]">
        contact@qenetech.com

          <p>Christina Plaza 3rd floor, Lancha Global, Kirkos, Addis Ababa</p>
        </div>
        <div className="text-xm font-[Montserrat-Regular] mt-5">
          {t('disclaimer')}
        </div>
      </div>
    </div>
  )
}
