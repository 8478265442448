import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { purchaseProduct } from '../utils/purchaseProduct';
import { fetchIpAddress } from '../utils/utilFunctions';
import {CONFIG} from '../utils/CONFIG';

const useHandleSubmit = (userData, selectedGift, usernameInput, location, payingWith) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorWeb, setErrorWeb] = useState(null);
  const { i18n } = useTranslation();

  const handleSubmit = async () => {
    setIsLoading(true);
    const locatios = await fetchIpAddress()
    if (usernameInput === '') {
      setErrorWeb('Please enter a username');
      setIsLoading(false);
      return;
    }
    // !productId || !userId || !giftData || !giftData.GiftId || !channel
    if (locatios.country -= 'ET') {
      try {
        let value = await purchaseProduct(
          selectedGift.purchase.stripe_product_id,
          userData?.userId ? userData.userId : userData.UserId,
          userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName,
          {
            GiftId: selectedGift.notification.GiftId,
            GifterId: usernameInput,
            GifterName: usernameInput,
            GifterImageUrl: 'https://example.com/image.jpg',
            GifterCountry: locatios.country,
          }
        );
        setIsLoading(false);
        await localStorage.setItem('gift', selectedGift.display.Name);
        await localStorage.setItem('userGifted', userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName);
        await localStorage.setItem("profile", userData.profilePicture);
        return null;
      } catch (error) {
        setIsLoading(false);
        return null;
      }
    } else {
      try {
        let userName = userData?.channelName;        
        const response = await fetch(`${CONFIG.backendUrl}/giftStreamer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: userName,
            streamerId: userData?.userId ? userData.userId : userData.UserId,
            gift: selectedGift.display.Name,
            amount: selectedGift.purchase.chapa_price,
            userId: usernameInput,
            giftID: selectedGift.notification?.GiftId,
            GifterCountry: locatios.country,
          }),
        });
        let res = await response.json();
        if (!response.ok) {
          setErrorWeb('Failed to send gift');
        } else {
          if (res.paymentUrl) {
            window.location.href = res.paymentUrl;
            localStorage.setItem('gift', selectedGift.display.Name);
            localStorage.setItem('userGifted', userData?.tiktokChannel);
            localStorage.setItem("profile", userData.profilePicture);
          }
          setErrorWeb('');
        }
      } catch (error) {
        setErrorWeb('An error occurred while sending the gift');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return { handleSubmit, isLoading, errorWeb };
};

export default useHandleSubmit;