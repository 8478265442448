
export const CONFIG = {
    domain: process.env.REACT_APP_DOMAIN,
    backendUrl: process.env.REACT_APP_FIREBASE_BACKEND,
    database: process.env.REACT_APP_FIRESTORE_DATABASE_ID,
    stripeBackendUrl: process.env.REACT_APP_STRIPE_BACKEND_URL,
    stripeAccessKey: process.env.REACT_APP_STRIPE_ACCESS_KEY,
    paths: {
        success: '/done/{channel}?session_id={CHECKOUT_SESSION_ID}',
        cancel: '/cancel',
    },
    apiKey: 'SN1770OD5N0IUAH4JNN5AWUQOE48FC'
}
