import React, { useEffect } from 'react';
import YouTube from 'react-youtube';

const YouTubeStream = ({ videoId }) => {
 
    const onReady = (event) => {
        event.target.pauseVideo(); // Pause the video when it's ready
      };

    const opts = {
        height: '500',
        width: '100%',
        playerVars: {
            autoplay: 0,
            controls: 0,
            rel: 0,  // Prevents showing related videos at the end
        },
    };

    return <YouTube className='w-[90%] md:w-[80%] rounded-lg' videoId={videoId} autoplay={false} opts={opts} onReady={onReady} />;
};

export default YouTubeStream;