import React from "react";
import "./trivia.css"; // Make sure you have the CSS in the same folder

const TriviaAnimation = () => {
  return (
    <div className="container-animation-trivia m-auto">
      <div className="holder" style={{ top: "25%", left: "20%" }}>
        <div className="base c_phone">
          <div className="holder" style={{ top: "-9%", left: "25%" }}>
            <div className="base c_trivia_bg">
              <div
                className="holder"
                style={{ top: "37%", left: "21%", width: "64%" }}
              >
                <div className="c_trivia_question">
                  <div
                    className="c_question_typing"
                    style={{ animationDelay: "1s" }}
                  >
                    Name a city in
                  </div>
                  <div
                    className="c_question_typing"
                    style={{ animationDelay: "1.6s" }}
                  >
                    Europe
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Answers */}
          {[
            { text: "Paris", delay: "2s", top: "33%", left: "25%" },
            { text: "Berlin", delay: "2.5s", top: "41%", left: "28%" },
            { text: "Amsterdam", delay: "3s", top: "49.8%", left: "30.3%" },
            { text: "London", delay: "3.5s", top: "58.6%", left: "33.6%" },
          ].map((item, idx) => (
            <div
              key={idx}
              className="holder"
              style={{ top: item.top, left: item.left }}
            >
              <div className="c_trivia_answer">
                <div
                  className="c_question_typing"
                  style={{ animationDelay: item.delay }}
                >
                  {item.text}
                </div>
              </div>
            </div>
          ))}

          {/* Decorative Items */}
          <div
            className="holder"
            style={{ top: "56%", left: "16%", scale: "0.4" }}
          >
            <div className="base c_trivia_item_1"></div>
          </div>
          <div
            className="holder"
            style={{ top: "33%", left: "61%", scale: "0.42" }}
          >
            <div
              className="base c_trivia_item_2"
            //   style={{ animationDelay: "0.3s" }}
            ></div>
          </div>
          <div
            className="holder"
            style={{ top: "26%", left: "-13%", scale: "0.32" }}
          >
            <div
              className="base c_trivia_item_3"
            //   style={{ animationDelay: "0.6s" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TriviaAnimation;
