import React, { useState } from 'react';

const useBanking = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);

    const updateBankDetails = async () => {
        setLoading(true);
        setError(null);

        const token = await localStorage.getItem('token');
        console.log('token', token);

        try {
            const res = await fetch(`${process.env.REACT_APP_FIREBASE_BACKEND}/saveBank`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await res.json();
            return data
        } catch (err) {
            return error.message
        } finally {
            setLoading(false);
        }
    };

    const saveStreamerBankDetails = async (bankDetails) => {
        setLoading(true);
        setError(null);

        const token = await localStorage.getItem('token');
        console.log('token', token);

        try {
            const res = await fetch(`${process.env.REACT_APP_FIREBASE_BACKEND}/saveBank`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,

                },
                body: JSON.stringify(bankDetails),
            });
            console.log("Banking Info Save", bankDetails);
            console.log("Banking Info Save Response", res);
            

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await res.json();
            setResponse(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    const getBankDetails = async () => {
        setLoading(true);
        setError(null);

        const token = await localStorage.getItem('token');
        console.log('token', token);

        try {
            const res = await fetch(`${process.env.REACT_APP_FIREBASE_BACKEND}/saveBank`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await res.json();
            console.log('data', data);
            
            setResponse(data);
            return data
        } catch (err) {
            setError(err.message);
            return null
        } finally {
            setLoading(false);
        }
    };

    
    return { saveStreamerBankDetails, loading, error, response, getBankDetails, updateBankDetails };
};

export default useBanking;