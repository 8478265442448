import React, { useState, useCallback, useEffect } from 'react';
import { useTransition, animated } from '@react-spring/web';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import './carousalAnimation.css';

export default function AnimatedCarousel({ onIndexChange }) {
  const {t, i18n} = useTranslation();
  const sentences = [
    t('hero1'),
    t('hero2')
  ];

  const [index, setIndex] = useState(0);
  const currentSentence = sentences[index];
  const letters = currentSentence.split('');

  // Animate letter entry only with the rotateX effect.
  const letterTransitions = useTransition(letters, {
    from: { transform: 'rotateX(90deg)', transformOrigin: 'center bottom', color: 'yellow' },
    enter: { transform: 'rotateX(0deg)', transformOrigin: 'center bottom', color: 'white' },
    // No leave animation so that old letters disappear immediately when index changes.
    keys: (item, idx) => `${index}-${idx}`, // include sentence index in the key
    trail: 40, // stagger each letter by 100ms
    config: { tension: 200, friction: 10 }
  });

  // Function to cycle through sentences.
  const reset = useCallback(() => {
    setIndex((prev) => {
      const newIndex = (prev + 1) % sentences.length;
      if (onIndexChange) onIndexChange(newIndex);
      return newIndex;
    });
  }, [onIndexChange, sentences.length]);

  useEffect(() => {
    console.log('Set timer for 7000 at ' + new Date().toLocaleTimeString());
    const interval = setInterval(reset, 6800);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container} style={{ overflow: 'hidden', height: '100px' }}>
      {/* Keying this inner div by index ensures the previous sentence is unmounted immediately */}
      <div
        key={index}
        className="text-4xl md:text-6xl text-white font-[Montserrat-Bold] md:text-left text-center w-full md:h-[100px] sentence"
        style={{ whiteSpace: 'pre' }} // Ensures spaces are preserved
      >
        {letterTransitions((style, letter, _, idx) => (
          <animated.span
            className="letter"
            key={`${index}-${idx}`}
            style={{ ...style, display: 'inline-block' }}
          >
            {letter === ' ' ? '\u00A0' : letter}
          </animated.span>
        ))}
      </div>
    </div>
  );
}
