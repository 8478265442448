import React from 'react';
import { useParams } from 'react-router-dom';

export default function Great() {
    const { channel } = useParams(); // Access the route parameter
    const [loading, setLoading] = React.useState(true);
    const [channelGifted, setChanelGifter] = React.useState('');
    const [seconds, setSeconds] = React.useState(15);

    let profile = React.useRef(null);
    let primary = React.useRef(null);

    function isTikTokWebView() {
        return navigator.userAgent.includes("Byte");
    }
    
    function closeWebView() {
        // Try closing the WebView (may not always work)
        if (window.TikTokJSBridge && window.TikTokJSBridge.closeWebView) {
            window.TikTokJSBridge.closeWebView();
        } else {
            // If not possible, try navigating to TikTok (fallback)
            window.location.href = "tiktok://";
        }
    }
        

    // Intercept Back Button
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
        if (isTikTokWebView()) {
            closeWebView();
            window.history.pushState(null, null, window.location.href); // Prevent further back navigation
        }
    };

    const getUserDAta = async () => {
        const dat = await localStorage.getItem('userGifted')
        let userProfile = await localStorage.getItem('profile')
        profile.current = userProfile
        primary.current = dat
        await setChanelGifter(dat)
        await localStorage.removeItem('userGifted');
        await localStorage.removeItem('gift');
        await localStorage.removeItem('isProcessing');
        setLoading(false);
    }

    React.useEffect(() => {
        const pathParts = window.location.pathname.split('/');
        const username = decodeURIComponent(pathParts[pathParts.length - 1]);
        primary.current = username
        getUserDAta()

    }, []);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (window.TikTokJSBridge && window.TikTokJSBridge.closeWebView) {
                window.TikTokJSBridge.closeWebView({
                    success: function() {
                        console.log("Webview closed successfully");
                    },
                    fail: function(err) {
                        console.error("Failed to close webview:", err);
                    }
                });
            } else {
                console.log("TikTokJSBridge or closeWebView method does not exist");
            }
            console.log('10 seconds have passed');
        }, 15000);

        return () => clearTimeout(timer);
    }, []);

    React.useEffect(() => {
        if (loading) return;
        const interval = setInterval(() => {
            setSeconds(prev => {
                if (prev <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [loading]);

    if (loading) return <div>Loading</div>
    return (
        <div className='min-h-[100vh] flex flex-col items-center justify-center bg-[white] px-5 md:px-0'>
            <h1 className="text-gradient text-6xl font-[Montserrat-Bold]">{ "Great Job"}</h1>
            <style jsx>{`
            .text-gradient {
                background: linear-gradient(to right, #FED507 0%, #FF368A 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        `}</style>
            <p className='text-gradient text-center text-xl md:text-4xl font-[Montserrat-Bold] pb-6'>You've sent your gift successfully</p>
            <img className='w-[200px] h-[200px] rounded-full  bg-[#a09f9f] border-4  border-white' src={profile.current} alt="Avatar" />
            <p className='font-[Montserrat-Bold] px-5 py-2 text-xl'>{channel}</p>
            <p className='font-[Montserrat-Bold] mt-10 text-gradient text-2xl text-center'>Your gift will be shown on the livestream in <br></br><span className='text-4xl md:text-6xl'>{60} seconds.</span></p>
            
            {isTikTokWebView() ? (
                <a className='from-[#FED507] to-[#FF368A] px-5 py-2 rounded-md font-[Montserrat-Bold] mt-10 text-2xl'>Go back to the livestream to see it</a>
            ) : (
                <a className=' bg-gradient-to-r from-[#FED507] to-[#FF368A] px-5 py-2 rounded-md text-white font-[Montserrat-Bold] mt-10' href={`https://tiktok.com/@${channel}/live`}>Go to the livestream</a>
            )}
        </div>
    );
}
