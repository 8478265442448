import React, { useState } from 'react';
import bee from '../../images/gift.svg';
import moment from 'moment';
import { Badge, Card, Space, Modal, Input, Select, Button } from 'antd';
import axios from 'axios';
import { LogoutOutlined } from '@ant-design/icons';
import { CONFIG } from '../utils/CONFIG';
import Loading from '../BeemiLive/components/Loading';
import useBanking from '../customHook/useBanking';

const { Option } = Select;



export default function MGifts() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userDataLoaded, setUserDataLoaded] = useState(true);
    const [error, setError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fullName, setFullName] = useState('');
    const [accountType, setAccountType] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { saveStreamerBankDetails, response, getBankDetails, updateBankDetails } = useBanking();
    const [bankingData, setBankingData] = useState(null);
    const [payoutData, setPayoutData] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const url = `${CONFIG.backendUrl}/login`;
            const body = { username, password };

            const response = await axios.post(url, body, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data;
            if (response.status === 200) {
                localStorage.setItem('token', data.token);
                setError('');
                fetchUserData(data.token);
            } else {
                setError(data.message || 'Invalid username or password');
                setUserData(null);
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
            setUserData(null);
        } finally {
            setLoading(false);
        }
    };

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get(`${CONFIG.backendUrl}/getUserData`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200) {
                setUserData(response.data.userGifts);
                setData(response.data);
            } else {
                setError('Failed to fetch user data');

            }
        } catch (error) {
            setError('An error occurred while fetching user data');


        } finally {
            setUserDataLoaded(false);
        }
    };

    const updateBankDetailsAlerter = async () => {
        let data = await updateBankDetails()
        setPayoutData(data);
    }

    const handleLogout = () => {
        Modal.confirm({
            title: 'Confirm Logout',
            content: 'Are you sure you want to logout?',
            onOk: () => {
                localStorage.removeItem('token');
                setUserData(null);
                setData(null);
            }
        });
    };
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        let data = {
            phoneNumber,
            fullName,
            accountType
        }
        await saveStreamerBankDetails(data)
        setIsModalVisible(false);
    };
    const respndant = async () => {
        let datas = await getBankDetails();
        console.log(data);

        setBankingData(datas);
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchUserData(token);
            respndant()
        }
        else {
            setUserDataLoaded(false);
        }
    }, [userData?.username]);

    if (userDataLoaded) return <Loading />;




    return (
        <div className='min-h-screen flex flex-col justify-center items-center bg-gray-100'>
            <div className='bg-white p-8 rounded shadow-md w-full max-w-2xl mt-[25vh] mb-[15vh]'>
                <div className='flex flex-row justify-between items-center'>
                    <h2 className='text-2xl font-bold mb-6 text-center text-[#F88259] font-[Montserrat-Bold]'>View My Gifts </h2>
                    {userData !== null &&
                        <div onClick={handleLogout} className='flex flex-row items-center cursor-pointer'>
                            <h1 className='mr-2 font-[Montserrat-Bold] text-[#F88259]'>Logout</h1>
                            <LogoutOutlined className='text-[#F88259] font-extrabold cursor-pointer' />
                        </div>}
                </div>
                {userData === null && <form onSubmit={handleLogin} className='space-y-4'>
                    <div>
                        <label className='block text-gray-400 font-[Montserrat-Bold]'>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className='w-full px-3 py-1 border rounded focus:outline-none focus:ring focus:border-[#F88259]'
                        />
                    </div>
                    <div>
                        <label className='block text-gray-400 font-[Montserrat-Bold]'>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className='w-full px-3 py-1 border rounded focus:outline-none focus:ring focus:border-[#F88259]'
                        />
                    </div>
                    <button
                        disabled={loading}
                        type="submit"
                        className='w-full bg-gradient-to-r from-yellow-500 to-[#F2419C] text-white py-2 rounded font-[Montserrat-Bold] shadow-xl transition duration-200'
                    >
                        Login {loading && '...'}
                    </button>
                    <p className='text-gray-500 text-sm'>
                        By logging in, you agree to our terms and conditions and privacy policy.
                    </p>
                </form>}
                {error && <p className='mt-4 text-red-500'>{error}</p>}
                {userData && (
                    <div className='mt-6 bg-gradient-to-r from-yellow-500 to-[#F2419C] min-h-[200px] p-4 rounded'>
                        {userData.map((item, index) => (
                            <Badge.Ribbon className='mr-3 overflow-hidden' text={item?.paymentProvider} color={item?.paymentProvider === 'stripe' ? '#FF467E' : '#F99F20'} key={index}>
                                <div className='flex items-center flex-row justify-between space-x-2 border-b-2 mt-2 pb-2'>
                                    <div className='flex flex-col w-[100%]'>
                                        <div className='flex flex-row justify-between items-center mt-5'>
                                            <div className='flex flex-col items-center'>
                                                <p className='text-white font-[Montserrat-Bold] uppercase'>{item?.paymentData.amount ? item?.paymentData.amount : item?.paymentData?.amount_subtotal}{" "}{item?.paymentData?.currency}</p>
                                            </div>
                                            <p className='text-white font-[Montserrat-Bold] flex flex-row items-center'><img src={bee} className='w-10 h-10' />{item.giftEvent.GiftId}</p>
                                        </div>
                                        <div className='flex flex-row items-center justify-between w-[100%]'>
                                            <span className='font-[Montserrat-Bold] text-sm text-[white]'>Gift Received From:{"  "} {item.giftEvent?.GifterId}</span>
                                            <span className='font-[Montserrat-Bold] text-xs text-[gray] pl-3'>{moment(item.paymentData.updated_at).format('ddd MMMM yy')}</span>
                                        </div>
                                    </div>
                                </div>
                            </Badge.Ribbon>
                        ))}
                        {(() => {
                            const totals = userData.reduce((acc, item) => {
                                const currency = item.paymentData.currency;
                                if (!acc[currency]) {
                                    acc[currency] = { total: 0, charges: 0 };
                                }
                                acc[currency].total += item.paymentData.amount;
                                acc[currency].charges += item.paymentData.charge || 0;
                                return acc;
                            }, {});

                            const etbTotals = userData
                                .filter(item => item.paymentData.currency === 'ETB')
                                .reduce((acc, item) => {
                                    acc.total += item.paymentData.amount;
                                    acc.charges += item.paymentData.charge || 0;
                                    return acc;
                                }, { total: 0, charges: 0 });

                            const usdTotals = userData
                                .filter(item => item.paymentData.currency === 'usd')
                                .reduce((acc, item) => {
                                    acc.total += item.paymentData.amount_subtotal;
                                    acc.charges += item.paymentData.charge || 0;
                                    return acc;
                                }, { total: 0, charges: 0 });



                            return (
                                <div className='mt-4 text-white font-[Montserrat-Bold] bg-[#393333] p-4 rounded'>
                                    <p>Total Amount (ETB)::  {etbTotals.total} ETB</p>
                                    <p>Total Charges (ETB):: {etbTotals.charges ? etbTotals.charges : 0} ETB</p>
                                    <p className="underline underline-offset-4">Net Amount (ETB):: {etbTotals.total - (etbTotals.charges ? etbTotals.charges : 0)} ETB</p>
                                    <div className='bg-white h-5 my-5 rounded-sm'></div>
                                    <p>Total Amount (USD): {usdTotals.total} USD</p>
                                    <p>Total Charges (USD): {usdTotals ? usdTotals.charges : 0} USD</p>
                                    <p className="underline underline-offset-4">Net Amount (USD): {usdTotals.total - (usdTotals.total ? usdTotals.charges : 0)} USD</p>

                                    <div className=' mt-5 overflow-hidden items-center flex flex-row justify-between font-[Montserrat-Bold]'>
                                        <div className='bg-white  rounded-sm text-black p-5 justify-around items-center flex flex-col'>
                                            <a className='uppercase font-[Montserrat-Bold] text-[#F2419C]'>{bankingData?.fullName && bankingData?.fullName}</a>
                                            <a>{bankingData?.phoneNumber}</a>
                                            <a>{bankingData?.accountType}</a>
                                        </div>
                                        <div className='flex flex-col font-[Montserrat-Bold]  items-left space-x-4'>
                                            <button onClick={showModal} className='text-[#F2419C] uppercase border-[1px] rounded-md px-5 mb-5'>edit Or add</button>
                                            <button onClick={updateBankDetailsAlerter} className='text-[#F2419C] uppercase border-[1px] rounded-md px-5'>Request</button>
                                        </div>
                                    </div>
                                    <br />
                                   
                                    {payoutData &&  <span className='text-[#888787] font-[Montserrat-Normal]'>{payoutData?.message}</span>}
                                </div>
                            );
                        })()}
                    </div>
                )}
            </div>
            <Modal title={<h1 className='font-[Montserrat-Bold] uppercase text-[#F2419C]'>Withdraw Funds</h1>} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <div className='space-y-4'>
                    <div>
                        <label className='block text-gray-700'>Bank Name</label>
                        <Select
                            value={accountType}
                            onChange={(value) => setAccountType(value)}
                            required
                            placeholder='Select Account Type'
                            className='w-full'
                        >
                            <Option value="CBE">Commercial Bank of Ethiopia</Option>
                            <Option value="Awash">Awash Bank</Option>
                            <Option value="telebirr">telebirr</Option>
                        </Select>
                    </div>
                    <div>
                        <label className='block text-gray-700'>Acc. No</label>
                        <Input
                            type="text"
                            value={phoneNumber}
                            placeholder='Enter your account number'
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label className='block text-gray-700'>Full Name</label>
                        <Input
                            type="text"
                            placeholder='Enter Full Name'
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
}
