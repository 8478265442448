import React from 'react'
import play from '../../images/how.mp4'

export default function About() {
    return (
        <div className="bg-[white]">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <h1 className="text-[#8D0EF5] font-[Montserrat-Bold] text-center pt-[20vh] text-3xl md:text-5xl ">How to <br /> use and install Beemi</h1>
                <p className="!font-[Montserrat-Regular] text-[#444444]  text-center px-[5vw] md:px-[20vw] py-[10vh] text-2xl md:text-3xl font-extralight ">This quick video will take you through everything you need to know to start live-streaming games with your friends and engaging with your audience on TikTok. Watch as we cover downloading Beemi, signing up, creating groups, choosing games, and connecting your live stream to TikTok—all in just a couple of minutes!”</p>
                <div className="w-[80vw] md:w-[30vw]  bg-[#D9D9D9] self-center flex justify-center items-center shadow-lg">
                    <video autoPlay controls >
                        <source src={play} className='w-[100%] '  />
                    </video>
                </div>
                <p className="body-data" style={{ maxWidth: '50%', textAlign: 'center' }}>For a clear explanation follow the steps below. If you still have a question,contact us and we will respond as soon as possible</p>
            </div>
            <div id="join-section">
                <div className="background-container">
                    <div className="join-section-background"></div>
                </div>

                <div className='px-2 md:px-56 justify-between'>
                    <div className="flex flex-row  items-center shadow-md px-5 md:px-14 py-4">
                        <div className="card-number">1</div>
                        <div className="card-description min-w-[50vw]">Download Beemi from Test flight</div>
                        <a href="https://testflight.apple.com/join/SpzGU7Cf" target="_blank" className="bg-[#8D0FF5] -mb-10 md:-mb-0 md:px-10 px-2 md:py-4 shadow-md rounded-3xl text-white -ml-20 md:!text-md !font-[Montserrat-Bold] text-xl">Download</a>
                    </div>
                    <br />
                    <div className="flex flex-row  items-center shadow-md px-5 md:px-14 py-4">
                        <div className="card-number">2</div>
                        <div className="card-description">Sign Up</div>
                        <a></a>
                    </div>
                    <br />

                    <div className="flex flex-row  items-center shadow-md px-5 md:px-14 py-4">
                        <div className="card-number">3</div>
                        <div className="card-description min-w-[50vw]">Create a Chat Group & Invite Friends</div>
                        <a></a>
                    </div>
                    <br />

                    <div className="flex flex-row  items-center shadow-md px-5 md:px-14 py-4">
                        <div className="card-number">4</div>
                        <div className="card-description">Start a Game & Video Chat</div>
                        <a ></a>
                    </div>
                    <br />

                    <div className="flex flex-row  items-center shadow-md px-5 md:px-14 py-4">
                        <div className="card-number">5</div>
                        <div  >
                            <h1 className="card-description min-w-[50vw]">Going Live on TikTok with Beemi</h1>
                            <p className="card-description min-w-[80vw] md:min-w-[50vw]" style={{ fontSize: '1rem', fontFamily: 'Montserrat-Regular' }}>To share your gameplay live on TikTok, open TikTok app, go live, and select ‘Mobile Gaming’ broadcast. Then, return to Beemi, hit ‘Live’, and enter your TikTok username to connect.</p>
                        </div>
                        <a ></a>
                    </div>
                    <div className="mx-[0vw] pt-[10vw] pb-[10vw]">
                        <h1 className="hero-pointer-1">Interact with Your Audience</h1>
                        <br />
                        <p className="body-data-1">Now that you’re live, your TikTok viewers can interact with your game through comments, likes, and gifts. Enjoy the enhanced engagement!</p>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
}
