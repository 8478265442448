import React, {useEffect} from 'react';
import one from '../../images/webp/sd.webp';
import two from '../../images/webp/Artboard 3.webp';
import four from '../../images/webp/Artboard 2.webp'
import BouncingAnimation from './Animation/BoostEngagment/BoostEngagment';
import TriviaAnimation from './Animation/CreateTrivia/CreateTrivia';

export default function Carousals({ index }) {

    let content;
    switch (index) {
        case 0:
            content = (
               <BouncingAnimation />
            );
            break;
        case 1:
            content = (
                <TriviaAnimation />
            );
            break;
    }

    React.useLayoutEffect(() => {
        const imgElement = document.querySelector('.composite-grid-img-1');
        if (imgElement) {
            // Remove the animation class
            imgElement.classList.remove('animate-grow');

            // Trigger reflow to restart the animation
            void imgElement.offsetWidth;

            // Re-add the animation class
            imgElement.classList.add('animate-grow');
        }
    }, [index]); // Run this effect whenever the index changes

    return content;
}
