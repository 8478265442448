import React, { use, useEffect, useCallback } from 'react';
import giftIcon from '../../../images/gift.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from './Loading'
import Error from './Error';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { CONFIG } from '../../utils/CONFIG';
import { useTranslation } from 'react-i18next';
import { fetchIpAddress } from '../utils/serivce'
import { Checkbox } from 'antd';
import useHandleSubmit from '../../customHook/useProcessPaymentHook';
import donation from '../../../images/affiliates/donate.png';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';




export default function MoreGifts() {
  const [usernameInput, setUsernameInput] = React.useState('');
  const navigate = useNavigate()
  const location = useLocation();
  let { user } = location.state || {};
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  // const [errorWeb, setErrorWeb] = React.useState(null);
  const [gifts, setGifts] = React.useState([]);
  const [giftSelected, setGiftSelected] = React.useState(false);
  const [userData, setUserData] = React.useState(user);
  const directRoute = React.useRef(false);
  const [selectedCardIndex, setSelectedCardIndex] = React.useState(null);
  // const [isLoading, setIsLoading] = React.useState(false);
  let [payingWith, setPayingWith] = React.useState('');
  const anonymous = React.useRef(false)
  const [selectedGift, setSelectedGift] = React.useState(null);
  const [donationLink, setDonationLink] = React.useState(null);
  const { handleSubmit, isLoading, errorWeb } = useHandleSubmit(userData, selectedGift, usernameInput, location, payingWith);


  const { i18n } = useTranslation();

  const [currency, setCurrency] = React.useState('');

  const radioButtonChange = (e) => {
    anonymous.current = e.target.checked;
    if (!e.target.checked) {
      setUsernameInput('')
    } else {
      setUsernameInput('Anonymous')
    }
  }




  useEffect(() => {
    setPaymentBasedOnLocation()
  }, [])


  useEffect(() => { }, [usernameInput])

  const handleInputChange = (e) => {
    setUsernameInput(e.target.value);
  };


  useEffect(() => {
    setPaymentBasedOnLocation()

  }, [i18n]);

  const setPaymentBasedOnLocation = async () => {
    const locatios = await fetchIpAddress()
    if (locatios?.country.toLowerCase() == 'et') {
      setCurrency('et')
      setPayingWith('chapa')
    }
    else {
      setPayingWith('default')
      setCurrency('default')
    }
    console.log(locatios);
  }

  let linker = React.useRef(null)


  const fetchGifts = async () => {
    const db = getFirestore(CONFIG.database);
    const giftsCollection = collection(db, 'gifts');
    console.log(giftsCollection);

    try {
      const giftsSnapshot = await getDocs(giftsCollection);
      const giftsList = giftsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).sort((a, b) => a.purchase.chapa_price - b.purchase.chapa_price);
      return giftsList;
    } catch (error) {
      setError('Failed to fetch gifts');
      return [];
    }
  };

  useEffect(() => {
    const loadGifts = async () => {
      const gifts = await fetchGifts();
      console.log("gifts are ", gifts);

      setGifts(gifts)
    };

    loadGifts();
  }, []);

  const fetchDonationLink = useCallback(async (UserId) => {
    if (!UserId) {
      setError('Streamer name is required');
      return;
    }

    const raw = JSON.stringify({ "streamerId": UserId });
    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${CONFIG.backendUrl}/getStreamerDonation`, requestOptions);
      if (!response.ok) {
        // setError('Failed to fetch donation link');
        return;
      }
      const result = await response.json();
      console.log('Donation Link:', result);
      setDonationLink(result.activeDonation[0]);
      return result.donation_link
    } catch (error) {
      // setError('Failed to fetch donation link');
      console.error("Error fetching donation link:", error);
      return null
    }
  }, [location.pathname]);



  const fetchStreams = useCallback(async () => {
    const pathParts = location.pathname.split('/');
    const usernameFromPath = pathParts[pathParts.length - 1];
    const raw = JSON.stringify({ "allStreamers": true, "Channel": usernameFromPath });
    const requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(`${CONFIG.backendUrl}/fetchStreams`, requestOptions);
      if (!response.ok) {
        setError('Network response was not ok');
      }
      const result = await response.json();
      console.log('%cResult:', 'color: red;', result);

      await setUserData(result)
      console.log(result.UserId);

      try {
        await fetchDonationLink(result.UserId);
      } catch (e) {
        console.log("donation fetch error", e);

      }
    } catch (error) {
      setError('Network response was not ok');
      setUserData([])
      console.error("confirmationn", error);
    } finally {
      setLoading(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!userData) {
      fetchStreams();
    } else {
      setLoading(false)
    }
  }, [fetchStreams, userData]);


  const onSelectGift = (gift, user) => {
    navigate(`/gift/${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}/${gift.id}`, { state: { user: user, gift: gift } });

  }


  const onSelectGiftWebView = (gift) => {
    console.log(gift);
    setSelectedGift(gift);

    setSelectedCardIndex(gift.id);

  }
  useEffect(() => {
    linker.current = setTimeout(() => {
      if (localStorage.getItem('isProcessing') === 'true') {
        setGiftSelected(null);
        let names = localStorage.getItem('userGifted');
        window.location.href = `/done/${names}`;

      }
    }, 1);
    return () => {
      clearTimeout(linker.current);
    };
  }, []);


  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream));
  };

  React.useEffect(() => {
    if (isWebView()) {
      directRoute.current = true;
    }
  }, []);


  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <Error reload error={error} />
    );
  }


  if (directRoute.current) {
    return (
      <div className={`w-[100vw]  bg-white ${directRoute.current ? 'py-0 md:py-0 md:mt-12 absolute top-0 min-h-[100vh] max-h-[100vh] overflow-hidden' : 'py-3 md:py-0 md:mt-12 h-[auto]'}`}>
        <Helmet>
          {/* Open Graph Meta Tags */}
          <title>{userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}'s Gift Page</title>
          <meta property="og:title" content={`${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}'s Gift Page`} />
          <meta property="og:description" content={`Check out the amazing gifts from ${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}!`} />
          <meta property="og:image" content={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} />
          <meta property="og:url" content={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}'s Gift Page`} />
          <meta name="twitter:description" content={`Check out the amazing gifts from ${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}!`} />
          <meta name="twitter:image" content={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} />
        </Helmet>
        <div className="relative mb-10 shadow-lg shadow-[#00000040] top-0 w-full h-[24vh] bg-gradient-to-r from-[#F99F20] to-[#F34893] grid place-items-center">
          <div className="relative">
            <div className='bg-gradient-to-r from-[#FFE500] to-[#FF368B] rounded-full border-2 border-white shadow-sm shadow-black'>
              <img src={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} alt="User" className="w-24 h-24 md:w-32 md:h-32 rounded-full border-[2px] border-[#ffffff56]" />
            </div>
            <div className={`absolute bottom-0 font-[Montserrat-Regular] text-xs px-2 mt-2 ${userData.isLive ? 'bg-[#FF0169] right-7' : 'bg-gray-400 right-3.5'} text-white border-2 border-white rounded-md`}>{userData.isLive ? "LIVE" : "Not LIVE"}</div>
          </div>
          <p className="text-white text-lg md:text-2xl font-bold mt-0 font-[Montserrat-Bold]">{userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}</p>
          <p className='text-sm text-white pb-2 font-[Montserrat-Bold]'>Show your support with gifts</p>
        </div>
        <div className="flex flex-row flex-wrap justify-normal items-center pb-3">
          {gifts.map((gift, index) => (
            <div key={index} onClick={() => onSelectGiftWebView(gift)} className={`w-[31vw] ${selectedCardIndex == gift.id ? 'bg-white' : 'bg-gradient-to-r from-[#F99F20] to-[#F34893]'} rounded-xl shadow-lg shadow-[#00000040] mb-2 mx-1`}>
              <div key={index} className={`${selectedCardIndex == gift.id ? 'bg-gradient-to-r from-[#F99F20] to-[#F34893]' : 'bg-white'} rounded-xl px-1  items-center ${selectedCardIndex == gift.id ? '' : ' m-[1px]'}`}>
                <img src={gift?.display?.ImageUrl} alt={`Gift ${index + 1}`} className="w-24 h-24 md:w-32 md:h-32 rounded-full" />
                <h3 className={`text-sm ${selectedCardIndex == gift.id ? 'text-white' : 'text-[#C7C7C7]'}  font-bold `}>{gift?.display?.Name}</h3>
                <p className={` font-[Montserrat-Bold] ${selectedCardIndex == gift.id ? 'text-white' : 'bg-gradient-to-r from-[#F99F20] to-[#F34893]text-4xl bg-clip-text text-transparent'}  `}>{gift?.display?.price[currency]}</p>
              </div>
            </div>
          ))}
          {donationLink && <div onClick={() => window.open(`https://chapa.link/donation/view/${donationLink?.link}`, '_blank')} className={`w-[31vw] h-[20vh] flex flex-col justify-center items-center bg-gradient-to-r from-[#F99F20] to-[#F34893] rounded-xl shadow-lg shadow-[#00000040] mb-2 mx-1`}>
            <div className={`bg-white w-[99%] h-full flex flex-col justify-center rounded-xl px-1  items-center m-[1px]`}>
              <img src={donation} alt="Gift" className="w-14 h-14 md:w-32 md:h-32 rounded-full" />
              <h3 className={`text-sm text-[#C7C7C7] font-bold `}>Donate</h3>
              <p className={` font-[Montserrat-Bold] bg-gradient-to-r from-[#F99F20] to-[#F34893]text-4xl bg-clip-text text-transparent`}>Donate</p>
            </div>
          </div>}
        </div>

        <div>
          {selectedCardIndex ? (
            <div className='flex flex-col justify-center items-center w-[100%] pt-8'>
              <div className='flex flex-col items-center justify-center w-[92%] py-[1.5px] px-[1.5px] rounded-[10px] mb-2 bg-gradient-to-r from-[#F99F20] to-[#F34893]'>
                <input
                  type='text'
                  placeholder='Enter your name'
                  required
                  className='border-0 px-2 py-1 mb-0  w-[100%] outline-none rounded-[10px] text-center font-[Montserrat-Bold]'
                  value={usernameInput}
                  onChange={handleInputChange}
                  disabled={anonymous.current}
                /></div>
              <div className='flex items-left justify-center'>
                <Checkbox className='text-[gray]' onChange={radioButtonChange}>Send Anonymously</Checkbox>
              </div>
              <span className='font-[Montserrat-Bold]  text-[red]'>{errorWeb}</span>

              <div className="flex justify-center bg-gradient-to-r from-yellow-500 to-[#F2419C] text-white shadow-md shadow-black mr-2 px-8 mb-2 rounded-md mt-1">
                <a onClick={!isLoading ? handleSubmit : null} className="font-[Montserrat-Bold] cursor-pointer text-gradient-to-r from-[#F99F20] to-[#F34893] text-white rounded flex items-center py-1">
                  {isLoading ? (
                    <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                  ) : (
                    <img src={giftIcon} alt="Gift" className="w-10 h-10" />
                  )}
                  {isLoading ? `Processing with ${payingWith}...` : 'Send Gift'}
                </a>
              </div>
            </div>
          ) : (
            <div className='flex flex-col justify-center items-center w-[100%] pt-10'>
              <style jsx>{`
              .text-gradient {
                  background: linear-gradient(to right, #FED507 0%, #FF368A 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
              }
              `}</style>
              <p className='font-[Montserrat-Bold] text-2xl'>Select a gift to send.</p>
            </div>
          )
          }
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-row justify-center items-center w-[100vw] h-[auto] bg-white py-3 md:py-0 md:mt-12'>
      <Helmet>
        {/* Open Graph Meta Tags */}
        <title>{userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}'s Gift Page</title>
        <meta property="og:title" content={`${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}'s Gift Page`} />
        <meta property="og:description" content={`Check out the amazing gifts from ${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}!`} />
        <meta property="og:image" content={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} />
        <meta property="og:url" content={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}'s Gift Page`} />
        <meta name="twitter:description" content={`Check out the amazing gifts from ${userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}!`} />
        <meta name="twitter:image" content={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} />
      </Helmet>
      <div className='flex flex-col min-h-[100vh] w-[100vw] justify-center items-center pt-32 md:pt-0'>
        <div className={`flex flex-col justify-between items-center !z-30 absolute md:top-[15%] ${giftSelected ? 'top-32' : 'top-28'}`}>
          <img src={userData?.profilePicture ? userData?.profilePicture : 'https://i.pravatar.cc/150?img=1'} alt='User' className='w-[100px] h-[100px] md:w-[180px] md:h-[180px] rounded-full z-20 bg-white border-[8px] border-[#e3caca]' />
          <br />
          <p className='text-white text-2xl font-[Montserrat-Bold]'>{userData?.tiktokChannel ? userData.tiktokChannel : userData.channelName}</p>
        </div>
        <div className='relative flex flex-col justify-center items-center bg-gradient-to-r from-[#F99F20] to-[#F34893] rounded-lg w-[93vw] h-auto md:h-[60vh] mt-[5vh] md:mt-0'>
          {userData ?
            <div className='relative flex flex-col md:flex-row w-[93vw] h-[100%] px-5 justify-end items-end mt-24 md:mt-10 space-x-4 pb-10'>
              {gifts.map((item, index) => (
                <div key={index} className='flex flex-col w-[100%] h-[180px] justify-end items-center bg-gray-100 p-4 rounded-lg shadow-md mt-10 md:mt-0'>
                  <img src={item?.display?.ImageUrl} alt={`Gift ${index + 1}`} className='md:w-44 md:h-44 w-28 h-28 rounded-full md:mb-4 rotate-0 md:top-[33%] mb-20 absolute' />
                  <div className='flex flex-row justify-between w-[100%] items-end'>
                    <div className='flex flex-col justify-center items-left'>
                      <span className='text-[#F88259] font-[Montserrat-Bold] text-lg'>{item?.display?.Name}</span>
                      <span className='text-gray-700 font-[Montserrat-Bold] text-'>{item?.display?.price[currency]}</span>
                    </div>
                    <div className="flex justify-center bg-gradient-to-r from-yellow-500 to-[#F2419C] text-white shadow-md shadow-black mr-1 px-2 mb-2 rounded-md">
                      <a onClick={() => onSelectGift(item, userData)} className="font-[Montserrat-Bold] text-sm md:text-md cursor-pointer text-gradient-to-r from-[#F99F20] to-[#F34893] text-white rounded flex items-center">
                        <img src={giftIcon} alt="Gift" className="md:w-10 md:h-10 w-6 h-6" />
                        Send Gift
                      </a>
                    </div>
                  </div>
                </div>

              ))}
              {donationLink && <div onClick={() => window.open(`https://chapa.link/donation/view/${donationLink?.link}`, '_blank')} className={`w-32 absolute top-0 bg-gradient-to-r  rounded-xl shadow-lg shadow-[#00000040] mb-2 mx-1`}>
                <div className={`bg-white w-32 rounded-xl px-1  items-center m-[1px]`}>
                  <img src={donation} alt="Gift" className="w-24 h-24 md:w-12 md:h-12 rounded-full" />
                  <h3 className={`text-sm text-[#C7C7C7] font-bold `}>Donate</h3>
                  <p className={` font-[Montserrat-Bold] bg-gradient-to-r from-[#F99F20] to-[#F34893]text-4xl bg-clip-text text-transparent`}>Donate</p>
                </div>
              </div>}
            </div> :
            <div className='flex flex-col justify-center items-center w-[100%] h-[100%] text-2xl text-white font-[Montserrat-Bold] capitalize'>User Doesnt Exist </div>}
        </div>
      </div>
    </div>
  );
}
