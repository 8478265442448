import React from 'react';

export default function Error({error}) {
    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <p className='text-lg mb-10 text-[red] font-[Montserrat-Bold] text-center'>Error: {error}</p>
            {error && (
                <button
                    onClick={() => window.location.reload()}
                    className="ml-4 px-4 py-2 bg-gradient-to-r from-[#F99F20] to-[#F34893] text-white rounded font-[Montserrat-Bold]"
                >
                    Reload
                </button>
            )}
        </div>
  );
}
