import React from 'react';
import Layout from '../Layout';
import Footer from '../Footer';
import { Outlet } from 'react-router-dom';

export default function LayoutMain() {

    return (
        <Layout>
            <Outlet />
            <Footer />
        </Layout>
    );
}
