import React from 'react';
import YouTubeStream from '../pages/Youtube';
import { useTranslation } from 'react-i18next';



export default function VedioHeader({ icon, title, body, link, vedio }) {
    console.log("vedio", vedio);
    
    const { t, i18n } = useTranslation();

    return (
        <div className='flex flex-col  items-center my-0 w-[100%] bg-gradient-to-r from-[#F34893] to-[#F99F20] pt-0' >
            <div className='bg-gradient-to-r from-[#F34893] to-[#F99F20] w-[100%] flex flex-col items-center py-1 overflow-hidden'>
                <YouTubeStream videoId={t(vedio)} title={title} />
            </div>
        </div>
    );
} 
