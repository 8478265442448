import React from 'react'
import { Outlet } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';


export default function Layout({ children }) {
    return (
        <ParallaxProvider>
            <div className="w-full max-w-[1800px] mx-auto scrool-smooth ">
                {children}
            </div>
        </ParallaxProvider>
    )
}
